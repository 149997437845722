import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const Trip = (props) => {
  console.log(props);
  return(
  <section>
{props.data.allContentfulTrip.edges.map(edge =>(
  <div key={edge.node.id}>
    <h2>
      {edge.node.title}
    </h2>
    <div>
      <Link to={`/trip/${edge.node.id}`}>
        Start
      </Link>
      
    </div>
  </div>
))
}
  </section>
  );
};

export default Trip

export const query = graphql`
{
  allContentfulTrip{
    edges {
      node {
        id
        title
      
      }
    }
  }
}

`
